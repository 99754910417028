
.noBorder {

}
.panel {
  box-shadow: 1px 1px 2px rgba(#333, 0.15);
  background: rgba(#999, 0.1);
  padding: 10px;
}
.panel + .panel {
  margin-top: 2rem;
}

.triangle {
  float: right;
}

.mainPanel > :global(.AppTitle) {
  text-align: right;
  margin-right: 14px;
}
