
.container {
  /*text-align: center;*/
}

.logo {
  margin-top: 30px;
  width: 50vw;
  min-width: 250px;
  max-width: 400px;
  height: auto;
}

.whatIsThis {
  padding: 15px 10px 0;
  max-width: 600px;

  > * {
    margin-top: 1em;

    & :first-child {
      margin-top: 0;
    }

  }
}
