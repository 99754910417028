.altBg {
  background: rgba(254, 252, 216, .75);
}

.note {
  background: rgba(253, 253, 243, 0.75);
  font-family: system, -apple-system, 'San Francisco', '.SFNSDisplay-Regular', 'Segoe UI', Segoe, 'Segoe WP', 'Helvetica Neue', helvetica, 'Lucida Grande', arial, sans-serif;
  padding: 5px;
  border-radius: 5px;
}
.note.note.note {
  line-height: 1.2;
  font-size: 12px;
  text-wrap: normal;
  word-break: normal;
}
.note:empty {
  padding: 0;
}

.note p {

}

.warningColor {
  color: #db9e27;
}

.filterWrap :global(.el-form--inline) {
  margin-top: 14px;
}

.truncatedCell > :global(.cell) {
  white-space: nowrap;
}

.stickyHeading {
  position: sticky;
  top: 60px;
  margin: -20px -20px 0 -20px;
  z-index: 20;
}
.isPinned {
    background: rgb(var(--header-bg-rgb) / 0.8);
}
.multiLineTransfer {
  display: flex;
  align-items: center;
}
.multiLineTransfer :global(.el-transfer-panel__item) {
  height: auto;
}
.multiLineTransfer :global(.el-transfer-panel) {
  flex: 1;
}

.formRows {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.formRow {
  display: grid;
  gap: 1rem;
}
.rowStreet {
  grid-template-columns: 1fr 240px;
}
.rowLocality {
  grid-template-columns: 100px 1fr 240px;
}
.streetNumber.streetNumber.streetNumber {
}
.zip.zip.zip {
}