
@use "~stylesheets/vars";
.wrap {
  position: relative;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}
.icon {
  cursor: pointer;
  fill: #666666;
}
.default {
  width: 38px;
}
.small {
  width: 24px;
}
.iconHasComments {
  fill: #{vars.$stja-purpur};
}
:global(html.dark) .iconHasComments {
  stroke: #fff;
  stroke-opacity: .5;
}
.count {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #{vars.$stja-orange};
  color: #000;
  padding: 2px 5px;
  border-radius: 50%;
  font-size: 10px;
}
