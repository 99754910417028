
.wrap {
  display: block;
  font-size: 80%;
  line-height: 1.4;
  margin-top: 10px;
}

.line {
  display: block;
}
